<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
      @show-modal="clear(obj)"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseSearchCondition
            :searchCondition="searchCondition"
            :searchConditionInfo="searchConditionInfo"
            @clear="clear(obj)"
            @search="search(obj)"
          >
          </BaseSearchCondition>
          <br />
          <!-- テーブル -->
          <BasePagingTable
            v-model="searchCondition.size"
            id="work-list-table"
            select-mode="single"
            :fields="fields"
            :displayModals="displayModals"
            :items.sync="tableItems"
            :selected-items.sync="selectedItem"
            :columnInfoPage="pagingInfo.page"
            :columnInfoSize="pagingInfo.size"
            :optionsPage="getListOptions(MASTER_CODE.LIST_PAGE, false)"
            :optionsSize="getListOptions(MASTER_CODE.LIST_SIZE, false)"
            :perPage="perPage"
            @size-changed="updateSize"
          />
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 選択ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.select"
          :disabled="!oneSelected"
          @click="select"
        />
        <!-- 選択解除ボタン -->
        <BaseButton
          v-if="useUnset"
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.unselect"
          @click="unselect"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 部署マスタモーダル -->
    <DepartmentModal
      :id="makeSubModalId"
      type="entry"
      :displayModals="displayModals"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  getCountPerReferencePage,
  openSubModal,
} from '@/common/Common.js'
import { search, clear } from '@/master/realVehicle/RealVehicleModal.js'
import { DELETED_KIND, DEPARTMENT_KIND } from '@/common/const.js'

export default {
  name: 'realVehicleModal',

  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
  },

  data() {
    return {
      /**
       * 選択された作業データリスト.
       * @type {Array}
       */
      selectedItem: [],

      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],

      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      perPage: getCountPerReferencePage(),
      obj: this,

      departmentModalSearchCondition: {},
    }
  },

  props: {
    selectedSearchCondition: {
      type: Object,
      default: () => {},
    },
    displayModals: {
      type: String,
      // 'one'  ->'real-vehicle-modal'
      // 'two'  ->'real-vehicle-modal-edit'
      // 'three'->'real-vehicle-modal-three'
      // 'four'->'real-vehicle-modal-four'
      default: 'one',
    },
    useUnset: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    selectedSearchCondition() {
      this.searchCondition.realVehicleKind = this.selectedSearchCondition.realVehicleKind
    },
  },

  computed: {
    pagingInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'paging'
      )
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.REAL_VEHICLE_MODAL_LIST
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        page: searchConditionInfo.page,
        size: searchConditionInfo.size,
        inputComponents: [
          {
            // 運送会社
            type: 'referenceCodeName',
            codeId: 'carrierCode',
            nameId: 'carrierName',
            codeColumnInfo: searchConditionInfo.carrier_code,
            nameColumnInfo: searchConditionInfo.carrier_name,
            btnColumnInfo: searchConditionInfo.sub_modal_carrier,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.CARRIER
              //this.openSubModal(this, 'department-modal-edit')
              this.openSubModal(this, this.makeSubModalId)
            },
          },
          {
            // 車番
            type: 'text',
            id: 'numberplate',
            columnInfo: searchConditionInfo.numberplate,
            maxLength: 30,
          },
          {
            // 車型
            type: 'select',
            id: 'vehicleModel',
            columnInfo: searchConditionInfo.vehicle_model,
            options: getListOptions(this.MASTER_CODE.VEHICLE_MODEL),
            onChangeCallback: () => {},
          },
          {
            // 区分（車両種類）
            type: 'select',
            id: 'realVehicleKind',
            columnInfo: searchConditionInfo.real_vehicle_kind,
            options: getListOptions(this.MASTER_CODE.REAL_VEHICLE_KIND_SEARCH),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.REAL_VEHICLE_MODAL_LIST
      )('result_ctrl_info')
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.REAL_VEHICLE_MODAL_LIST
      )(this.DOMAIN_NAME.FIELDS)
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    oneSelected() {
      return this.selectedItem.length === 1
    },

    /**
     * 部署参照モーダルID作成
     */
    makeSubModalId() {
      //開かれたdisplayModalsによりサブモーダルIDも変わるように修正
      let newId = 'department-modal'
      if (this.displayModals == 'two') {
        newId = 'department-modal-edit'
      } else if (this.displayModals == 'three') {
        newId = 'department-modal-three'
      } else if (this.displayModals == 'four') {
        newId = 'department-modal-four'
      }
      return newId
    },
  },

  methods: {
    getListOptions,
    search,
    clear,
    openSubModal,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let defaultKind = null
      if (this.selectedSearchCondition.realVehicleKind != null) {
        defaultKind = this.selectedSearchCondition.realVehicleKind
      }
      return {
        carrierId: null,
        carrierCode: null,
        carrierName: null,
        numberplate: null,
        realVehicleKind: defaultKind,
        vehicleModel: null,
        deleted: DELETED_KIND.NOT_DELETED,
        size: getSearchMaxCount(),
        screenId: this.SCREEN_ID.REAL_VEHICLE_MODAL_LIST,
        domainName: 'result_fields',
        isModalSearch: '1',
      }
    },

    select() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('real-vehicle-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('real-vehicle-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('real-vehicle-modal-three')
      } else if (this.displayModals == 'four') {
        this.$bvModal.hide('real-vehicle-modal-four')
      }
      this.$emit(
        'after-close-set',
        this.selectedItem[0]
        // this.selectedItem[0].real_vehicle_id,
        // this.selectedItem[0].numberplate,
        // this.selectedItem[0].vehicle_model,
        // this.selectedItem[0].vehicle_model_name,
        // this.selectedItem[0].real_vehicle_kind,
        // this.selectedItem[0].real_vehicle_kind_name
      )
    },

    unselect() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('real-vehicle-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('real-vehicle-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('real-vehicle-modal-three')
      } else if (this.displayModals == 'four') {
        this.$bvModal.hide('real-vehicle-modal-four')
      }
      this.$emit('after-close-unset')
    },

    updateSize: function (size) {
      this.searchCondition.size = size
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.searchCondition.carrierId = id
      this.searchCondition.carrierCode = code
      this.searchCondition.carrierName = name
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.searchCondition.carrierId = null
      this.searchCondition.carrierCode = null
      this.searchCondition.carrierName = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>
